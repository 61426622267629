import { Theme } from '@mui/material';
import { ttCommonsPro } from '../index';

export default function CSSBaseline(theme: Theme) {
    return {
        MuiCssBaseline: {
            styleOverrides: {
                '@font-face': [ttCommonsPro],
                '*': {
                    boxSizing: 'border-box'
                },
                body: {
                    margin: theme.spacing(0),
                    padding: theme.spacing(0),
                    width: '100%',
                    height: '100%'
                },
                input: {
                    '&[type=number]': {
                        MozAppearance: 'textfield',
                        '&::-webkit-outer-spin-button': {
                            margin: 0,
                            WebkitAppearance: 'none'
                        },
                        '&::-webkit-inner-spin-button': {
                            margin: 0,
                            WebkitAppearance: 'none'
                        }
                    }
                }
            }
        },
        MuiDrawer:{
            styleOverrides: {
                paper:{
                    borderRadius:0,
                    border: '1px solid #E6E6E6',
                    boxShadow:'0px 2px 8px 0px #0000000D !important'
                }
            }
        }
    };
}
