import Typography from '@mui/material/Typography';
import AddFinancialUser from '../../containers/add-financial-user';
import BreadcrumbsComponent from '../../components/molecules/Breadcrumbs/Breadcrumbs';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const FinancialAdvisor = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state }: any = useLocation();

    const breadcrumbs = [
        <Typography
            key="1"
            variant="body1"
            onClick={() => navigate('/admin')}
            sx={{
                color: 'primary.main',
                fontSize: '22px',
                cursor: 'pointer',
                '&:hover': {
                    textDecoration: 'underline 1px',
                    textUnderlineOffset: '3px'
                }
            }}
        >
            {t('TEXT_ADMIN_DASHBOARD_HEADER')}
        </Typography>,
        <Typography key="2" variant="body1" sx={{ color: 'primary.main', fontSize: '22px' }}>
            {state?.fromAdvisor ? t('FINANCIAL_ADVISORS_ADD_NEW_ADVISOR') : t('TEXT_ADMIN_USER_MANAGEMENT_ADD_NEW')}
        </Typography>
    ];
    return (
        <Container>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} />
            <AddFinancialUser fromAdvisor={state?.fromAdvisor} />
        </Container>
    );
};

export default FinancialAdvisor;
