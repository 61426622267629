import sortingArrowDown from '../../../../assets/icons/sorting_arrow_down.svg';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const Thead = styled('thead')(() => ({
    background: '#F1F5FB',
    border: 'none',
    height: '56px'
}));

const Th = styled('th')<{ width: number }>(({ theme, width }) => ({
    background: '#F1F5FB',
    border: 'none',
    paddingLeft: '16px',
    textAlign: 'left',
    width,
    ...theme.typography.tables.headers
}));

export const Header = () => {
    const { t } = useTranslation();

    return (
        <Thead>
            <tr>
                <Th width={72}></Th>
                <Th width={114}>{t('ADVISOR_CONSOLE_STATUS')}</Th>
                <Th width={200} style={{ paddingLeft: 0 }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px'
                        }}
                    >
                        <img width={12} height={12} src={sortingArrowDown} alt="Sort" />
                        {t('ADVISOR_CONSOLE_CLIENT_GOAL_NAME')}
                    </div>
                </Th>
                <Th width={120}>{t('ADVISOR_CONSOLE_RISK_AND_GOAL_PRIORITY')}</Th>
                <Th width={120}>{t('ADVISOR_CONSOLE_PORTFOLIO_VALUE')}</Th>
                <Th width={112}>{t('ADVISOR_CONSOLE_NUMBER_OF_GOALS')}</Th>
                <Th width={150}>{t('ADVISOR_CONSOLE_PROBABILITY_OF_SUCCESS')}</Th>
                <Th width={150}>{t('ADVISOR_CONSOLE_EQUITY_FIXED_INCOME_CASH')}</Th>
                <Th width={150}>{t('ADVISOR_CONSOLE_PLAN_CREATION_DATE')}</Th>
                <Th width={122} style={{ textAlign: 'center', padding: '0 24px' }}>
                    {t('ADVISOR_CONSOLE_ACTIONS')}
                </Th>
            </tr>
        </Thead>
    );
};
