import { CardContent, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { BasicButton } from '../../components/atoms';
import { Toast } from '../../components/molecules/Toast/Toast';
import { checkIfUserExists } from '../../features/adviceAdmin';
import { setApplicationMessage } from '../../features/messaging/messagingSlice';
import { addUserDetails } from '../../services/userDetails';
import UserInfo from '../add-new-financial-user';

const Container = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    fontStyle: 'normal',
    fontSize: '32px',
    color: theme.palette.primary.main,
    padding: '0',
    boxShadow: 'none',
    WebkitBoxShadow: 'none'
}));

const HeaderText = styled('span')(({ theme }) => ({
    color: theme.palette.common.black
}));

const ButtonContainer = styled('span')(() => ({
    display: 'inline-block',
    margin: '6px'
}));

interface FormInput {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
    accessLevel: string;
    tenantName: string;
    tenentGroup: string[];
}

const AddFinancialUser = ({ fromAdvisor }: any) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const tenant = useAppSelector((state) => state?.global?.globalConfig?.subdomain);
    const [isCheckingEmail, setIsCheckingEmail] = useState<any>(null);
    const [emailExistsError, setEmailExistsError] = useState<string | null>(null);
    const [addedAdvisorName, setAddedAdvisorName] = useState<boolean>(false);
    const [hasServerError, setHasServerError] = useState({ name: '', message: '', statusCode: 200 });
    const [loading, setLoading] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        watch,
        setError,
        reset
    } = useForm<FormInput>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            firstName: '',
            lastName: '',
            emailAddress: '',
            phoneNumber: '',
            accessLevel: '',
            tenantName: '',
            tenentGroup: ['']
        }
    });

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            checkForEmailDupe(watch('emailAddress'), Boolean(errors.emailAddress));
        }, 300);

        return () => clearTimeout(timeoutId);
    }, [watch('emailAddress')]);

    const checkForEmailDupe = async (value: string, hasError: any) => {
        if (value && !hasError) {
            setIsCheckingEmail(true);
            let res: any;
            try {
                res = await dispatch(checkIfUserExists(value));
            } catch (e) {
                setIsCheckingEmail(false);
                setEmailExistsError(null);
                return false;
            }

            if (res.payload) {
                setError('emailAddress', {
                    type: 'manual',
                    message: t('TEXT_CLIENT_INFO_EMAIL_ALREADY_EXISTS')
                });

                setEmailExistsError(t('TEXT_CLIENT_INFO_EMAIL_ALREADY_EXISTS'));
                setIsCheckingEmail(false);
                return false;
            }

            setIsCheckingEmail(false);
            setEmailExistsError(null);
            return true;
        }
    };

    const handleCancel = () => {
        navigate('/admin');
    };

    const onSubmit = async (values: {
        firstName: string;
        lastName: string;
        emailAddress: string;
        phoneNumber: string;
        accessLevel: string;
        tenantName: string;
        tenantGroup: any;
    }) => {
        const finished = await checkForEmailDupe(values.emailAddress, false);
        setLoading(true);
        if (finished) {
            const res: any = await dispatch(
                addUserDetails({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.emailAddress,
                    phoneNumber: values.phoneNumber,
                    role: fromAdvisor ? 'advisor' : 'admin',
                    tenant: values?.tenantGroup === 'hantz' ? 'hantz' : tenant,
                    tenants: [values?.tenantGroup]
                })
            );
            if (!res.error) {
                reset();
                setHasServerError({ message: '', name: '', statusCode: 200 });
                setAddedAdvisorName(!addedAdvisorName);
                setTimeout(() => {
                    navigate('/admin');
                }, 3000);
            } else if (res.payload?.status === 400) {
                setHasServerError({
                    message: res.payload?.response.data.message,
                    name: res.payload?.response.data.name,
                    statusCode: res.payload?.status
                });
                setTimeout(() => {
                    navigate('/admin');
                }, 3000);
            } else {
                dispatch(
                    setApplicationMessage({
                        showMessage: true,
                        message: t('TEXT_ERROR_ADDING_USER'),
                        variant: 'error'
                    })
                );
            }
        }

        setLoading(false);
    };

    const translation = {
        firstName: {
            label: fromAdvisor
                ? t('TEXT_ADD_USER_FIRST_NAME_LABEL_ADVISOR')
                : t('TEXT_ADD_USER_FIRST_NAME_LABEL_ADMIN'),
            placeholder: t('TEXT_ADD_USER_FIRST_NAME_PLACEHOLDER'),
            requiredErrorMessage: t('TEXT_ADD_USER_FIRST_NAME_REQUIRED_VALIDATION', {
                user: fromAdvisor ? t('TEXT_ADVISOR') : t('TEXT_ADMIN')
            }),
            maxValidationErrorMessage: t('TEXT_ADD_USER_FIRST_NAME_MAX_VALIDATION', {
                user: fromAdvisor ? t('TEXT_ADVISOR') : t('TEXT_ADMIN')
            }),
            minValidationErrorMessage: t('TEXT_ADD_USER_FIRST_NAME_MIN_VALIDATION', {
                user: fromAdvisor ? t('TEXT_ADVISOR') : t('TEXT_ADMIN')
            }),
            validationErrorMessage: t('TEXT_ADD_USER_FULL_NAME_INVALID_VALIDATION')
        },
        lastName: {
            label: fromAdvisor ? t('TEXT_ADD_USER_LAST_NAME_LABEL_ADVISOR') : t('TEXT_ADD_USER_LAST_NAME_LABEL_ADMIN'),
            placeholder: t('TEXT_ADD_USER_LAST_NAME_PLACEHOLDER'),
            requiredErrorMessage: t('TEXT_ADD_USER_LAST_NAME_REQUIRED_VALIDATION', {
                user: fromAdvisor ? t('TEXT_ADVISOR') : t('TEXT_ADMIN')
            }),
            maxValidationErrorMessage: t('TEXT_ADD_USER_LAST_NAME_MAX_VALIDATION', {
                user: fromAdvisor ? t('TEXT_ADVISOR') : t('TEXT_ADMIN')
            }),
            minValidationErrorMessage: t('TEXT_ADD_USER_LAST_NAME_MIN_VALIDATION', {
                user: fromAdvisor ? t('TEXT_ADVISOR') : t('TEXT_ADMIN')
            }),
            validationErrorMessage: t('TEXT_ADD_USER_FULL_NAME_INVALID_VALIDATION')
        },
        email: {
            label: t('TEXT_ADD_USER_EMAIL_LABEL'),
            placeholder: t('TEXT_ADD_USER_EMAIL_PLACEHOLDER'),
            requiredErrorMessage: t('TEXT_ADD_USER_EMAIL_REQUIRED_VALIDATION', {
                user: fromAdvisor ? t('TEXT_ADVISOR') : t('TEXT_ADMIN')
            }),
            validationErrorMessage: t('TEXT_ADD_USER_EMAIL_INVALID_VALIDATION')
        },
        phonenumber: {
            label: t('TEXT_ADD_USER_PHONE_NUMBER_LABEL'),
            placeholder: t('TEXT_ADD_USER_PHONE_NUMBER_PLACEHOLDER'),
            validationErrorMessage: t('TEXT_ADD_USER_PHONE_NUMBER_INVALID_VALIDATION')
        },
        tenantGroup: {
            label: t('TEXT_ADD_USER_PHONE_NUMBER_LABEL'),
            placeholder: t('TEXT_ADD_USER_PHONE_NUMBER_PLACEHOLDER'),
            validationErrorMessage: t('TEXT_ADD_USER_PHONE_NUMBER_INVALID_VALIDATION')
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Container>
            {addedAdvisorName && (
                <Toast
                    heading={fromAdvisor ? t('ADVISOR_ADDED_SUCCESSFULLY') : t('ADMIN_ADDED_SUCCESSFULLY')}
                    type="confirm"
                    disappearTime={4}
                    messages={[`${t('ADVISOR_ADDED_SUCCESSFULLY_MESSAGE')}`]}
                />
            )}

            {hasServerError.statusCode === 400 ? (
                <Toast
                    heading={`${hasServerError?.name}`}
                    type="error"
                    disappearTime={4}
                    messages={[`${hasServerError?.message} ${t('ADVISOR_ADD_USER_REJECTED_HELPER_MESSAGE')}`]}
                />
            ) : null}

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <HeaderText>
                    {fromAdvisor ? t('FINANCIAL_ADVISORS_ADD_NEW_ADVISOR') : t('TEXT_ADMIN_USER_MANAGEMENT_ADD_NEW')}
                </HeaderText>
            </div>

            <UserInfo
                onSubmit={onSubmit}
                onCancel={handleCancel}
                handleSubmit={handleSubmit}
                emailExistsError={emailExistsError}
                isCheckingEmail={isCheckingEmail}
                control={control}
                errors={errors}
                isValid={isValid}
                translation={translation}
                t={t}
            />
            <div>
                <ButtonContainer
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        marginTop: '25px'
                    }}
                >
                    <BasicButton variant="outlined" onClick={handleCancel}>
                        {t('BUTTON_CANCEL')}
                    </BasicButton>
                    <BasicButton
                        type="submit"
                        form="add-user-form"
                        variant={!isValid || isCheckingEmail || emailExistsError ? 'outlined' : 'contained'}
                        disabled={!isValid || isCheckingEmail || emailExistsError}
                        sx={{ marginLeft: '20px' }}
                    >
                        {t('TEXT_ADMIN_ADD_USER_BUTTON')}
                    </BasicButton>
                </ButtonContainer>
            </div>
        </Container>
    );
};

export default AddFinancialUser;
