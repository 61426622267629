import { AdminTableItem } from 'components/molecules/AdminTable';
import { AdvisorConsoleStoreFilters } from './types';

export const getNumberOfPages = (itemsLength: number, itemsPerPage: number) => {
    return Math.ceil(itemsLength / itemsPerPage);
};

export const getItemsByPage = ({
    dataItems,
    itemsPerPage,
    page
}: {
    dataItems: AdminTableItem[];
    itemsPerPage: number;
    page: number;
}) => {
    return dataItems?.slice((page - 1) * itemsPerPage, page * itemsPerPage) ?? [];
};

export const filterDataItems = ({
    dataItems,
    filters
}: {
    dataItems: AdminTableItem[];
    filters: AdvisorConsoleStoreFilters;
}) => {
    const { client, riskProfile, priority, type } = filters || {};

    return dataItems?.filter(({ clientGoalName, riskGoalPriority, status, subItems = [] }) => {
        const validations: boolean[] = [];

        if (priority) {
            validations.push(
                subItems.some(
                    ({ goalPriority }) => goalPriority?.toUpperCase() === priority.toUpperCase()
                )
            );
        }

        if (type) {
            validations.push(
                subItems.some(({ goalType }) => goalType?.toUpperCase() === type.toUpperCase())
            );
        }

        if (riskProfile) {
            validations.push(riskProfile?.toUpperCase() === riskGoalPriority?.toUpperCase());
        }

        if (filters.status) {
            validations.push(filters.status?.toUpperCase() === status?.toUpperCase());
        }

        if (client?.value) {
            const isSameClient = clientGoalName?.toUpperCase() === filters.client?.value;

            validations.push(isSameClient);
        }

        return !validations.includes(false);
    });
};

export const calculateEquityFixedIncome = ({ portfolios, recommendedPortfolioId }: any) => {
    const portfolio = portfolios?.find(
        (_sPort: any) => _sPort.portfolioId === recommendedPortfolioId
    );

    return (
        portfolio?.assets
            ?.map(({ totalAllocatedvalue }: { totalAllocatedvalue: number }) => {
                return totalAllocatedvalue || 0;
            })
            .join(' / ') || ''
    );
};
