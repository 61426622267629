import TargetImage from '../../../assets/icons/target_rounded.svg';
import { Typography } from '../../atoms';
import styled from '@emotion/styled';

type Props = {
    title: string;
    infoText?: string;
};

const Container = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBlock: '4rem',
    flex: 1
}));

const TargetIcon = styled('img')(({}) => ({
    width: '100px',
    height: '100px',
    marginBottom: '12px'
}));

export const NoGoal = ({ title, infoText }: Props) => {
    return (
        <Container>
            <TargetIcon src={TargetImage} />
            <Typography mb="7px" color="neutral.black" variant="headers.h3">
                {title}
            </Typography>
            {infoText && (
                <Typography
                    color="neutral.grey.400"
                    variant="body.large"
                    sx={{ width: '255px', maxWidth: '100%', textAlign: 'center' }}
                >
                    {infoText}
                </Typography>
            )}
        </Container>
    );
};
