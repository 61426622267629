import { useEffect, useState } from 'react';
import { uipApiInstance } from './services';

export const Hantz = ({ accessToken }: { accessToken: any }) => {
    
    // const access_token = Api.getAccessToken();
    // const user_info = Access.getUserInfo();
    // const userAccess = Access.getAccess()?.filter((ac) => {
    //   if (ac.zone == zone_id) {
    //     return true;
    //   }
    //   // super admin
    //   if (ac?.app == "admin" && ac.zone == "acl" && ac.type == "admin") {
    //     return true;
    //   }
    //   return false;
    // });

    // const accessParams = encodeURIComponent(JSON.stringify(userAccess));
    // const userInfoParams = encodeURIComponent(JSON.stringify(user_info));
    // const url = `${external_url}${char}access_token=${access_token}&access=${accessParams}&user_info=${userInfoParams}`;

    // const external_url = 'https://apps.mosaiqdev.corp.frk.com/ms/'
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        // Define the API endpoint      
        const fetchData = async () => {
            try{
                const response = await uipApiInstance({
                    method: 'GET',
                    url: '/api/advice/getconfig'
                });
            const data = response;
            setData(data.data.hantzconfig.external_url);
        }
        catch (err:any) {
            setError(err.message);
          } finally {
            setLoading(false);
          }
      
        };

    
        fetchData();
      }, []); // If bearer token changes, refetch data
    

    const external_url = data;
    const char = external_url.indexOf('?') > -1 ? '&' : '?';

    const url = `${external_url}${char}access_token=${accessToken}`;
    if (loading) return <div>Loading...</div>;
    return (
        <>
            <iframe src={url} className="hantzIframe" />
        </>
    );
};
