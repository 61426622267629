import { Theme } from '@mui/material';

export default function Typography(theme: Theme) {
    return {
        MuiTypography: {
            styleOverrides: {
                //root: {
                //    lineHeight: 1.8
                //},
                paragraph: {
                    marginBottom: theme.spacing(2)
                },
                gutterBottom: {
                    marginBottom: theme.spacing(1)
                }
            }
        }
    };
}
