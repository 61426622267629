import { Theme } from '@mui/material';

export default function Select(theme: Theme) {
    return {
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: theme.palette.primary.main
                },
                select: {
                    minHeight: 0,
                    '& em': {
                        color: theme.palette.primary.main,
                        fontStyle: 'normal'
                    }
                },
                '&.Mui-error': {
                    borderColor: '#DC0546'
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    lineHeight: 1,
                    margin: 0,
                    padding: `${theme.spacing(1.75)} ${theme.spacing(2)}`,
                    fontSize: theme.spacing(2),
                    fontWeight: `600 !important`
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    marginTop: theme.spacing(1)
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 0
                }
            }
        }
    };
}
