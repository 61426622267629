import styled from '@emotion/styled';
import { Logo } from '../../atoms/Logo/Logo';
import { NavUser } from './User';
import { NavLink } from './Link';
import { NavItem } from 'common/types/nav';
import { NavContact } from './Contact';
import { useState } from 'react';
import { useAppSelector } from '../../../common/hooks';
import ellipse from '../../../assets/logos/Hantz_latest_logo.png';
import { Divider } from '@mui/material';

const Background = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 100
}));

const Navbar = styled('nav')(() => ({
    background: '#3769FF',
    height: '60px',
    padding: '0 30px',
    display: 'flex',
    alignItems: 'center',

    '& > #hantzlogo::before': {
        height: '30px',
        background: 'red',
        width: '40px',
        color: 'red'
    }
}));

const LinksContainer = styled('div')(() => ({
    display: 'flex',
    gap: '47px',
    alignItems: 'center',
    flex: 1,
    marginRight: '85px',
    marginLeft: '85px'
}));

const ImageContainer = styled('div')(() => ({
    height: '22px',
    width: '120px',
    backgroundImage: `url(${ellipse})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    marginLeft: '10px'
}));

type Props = {
    items: NavItem[];
    user: {
        loggedInUsername: string;
        userRole?: string;
        adminTitle?: string;
    };
    onClickAdmin(): void;
    onClickLogout(): void;
};

export const Nav = ({ items = [], user, onClickAdmin, onClickLogout }: Props) => {
    const [activeDropdown, setActiveDropdown] = useState<DropdownNames>();
    const tenantsList = useAppSelector((state) => state?.auth?.user?.tenants);
    const tenant = useAppSelector((state) => state?.auth?.user?.tenant);

    const onToggleDropdown = (name: DropdownNames) =>
        setActiveDropdown((oldName) => (oldName === name ? undefined : name));

    return (
        <>
            <Background>
                <Navbar>
                    <Logo />
                    {tenant === 'hantz' && tenantsList?.includes('hantz') ? (
                        <>
                            <Divider
                                style={{ marginLeft: '10px', color: 'rgba(0, 0, 0, 0.5)', borderColor: 'snow' }}
                                orientation="vertical"
                                variant="middle"
                                flexItem
                            />{' '}
                            <ImageContainer id="hantzlogo" />
                        </>
                    ) : null}
                    <LinksContainer>
                        {tenant === 'hantz' && tenantsList?.includes('hantz')
                            ? null
                            : items.map(({ label, ...item }, index) => (
                                  <NavLink key={`${label}_${index}`} {...item}>
                                      {label}
                                  </NavLink>
                              ))}
                    </LinksContainer>
                    <NavUser
                        {...user}
                        tenantsList={tenantsList}
                        tenant={tenant}
                        isDropdownActive={activeDropdown === 'user'}
                        onToggleDropdown={() => onToggleDropdown('user')}
                        onClickAdmin={onClickAdmin}
                        onClickLogout={onClickLogout}
                    />

                    <NavContact
                        isDropdownActive={activeDropdown === 'contact'}
                        onToggleDropdown={() => onToggleDropdown('contact')}
                    />
                </Navbar>
            </Background>
        </>
    );
};

type DropdownNames = 'user' | 'contact';
