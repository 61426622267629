import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-input-mask';
import { ComboSelect, TextboxInput, Typography } from '../../components/atoms';

const GridContainer: any = styled(Grid)(() => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    marginTop: '2px'
}));

const ErrorSpan = styled('span')(() => ({
    color: 'red',
    fontSize: '12px'
}));

export default function UserInfo({ onSubmit, handleSubmit, control, errors, translation, emailExistsError }: any) {
    const { t } = useTranslation();

    const tenentGroupOptions = [
        { label: 'advice', value: 'advice' },
        { label: 'hantz', value: 'hantz' }
    ];

    const [selectedOption] = useState('');

    return (
        <form id="add-user-form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <GridContainer container spacing={2}>
                <GridContainer item xs={12} sm={6} md={3} lg={3} sx={{ lineHeight: '20px' }}>
                    <Typography variant="body1">{translation.firstName.label}</Typography>
                    <Controller
                        name="firstName"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: translation.firstName.requiredErrorMessage
                            },
                            pattern: {
                                value: /^[0-9A-Za-zñáéíóúü_ ]*$/i,
                                message: translation.firstName.validationErrorMessage
                            },
                            maxLength: {
                                value: 50,
                                message: translation.firstName.maxValidationErrorMessage
                            },
                            minLength: {
                                value: 2,
                                message: translation.firstName.minValidationErrorMessage
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextboxInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                error={!!errors.firstName}
                                type="text"
                                placeholder={translation.firstName.placeholder}
                            />
                        )}
                    />
                    {errors.firstName && <ErrorSpan role="alert">{errors.firstName.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer item xs={12} sm={6} md={3} lg={3} sx={{ lineHeight: '20px' }}>
                    <Typography variant="body1">{translation.lastName.label}</Typography>
                    <Controller
                        name="lastName"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: translation.lastName.requiredErrorMessage
                            },
                            pattern: {
                                value: /^[0-9A-Za-zñáéíóúü_ ]*$/i,
                                message: translation.lastName.validationErrorMessage
                            },
                            maxLength: {
                                value: 50,
                                message: translation.lastName.maxValidationErrorMessage
                            },
                            minLength: {
                                value: 2,
                                message: translation.lastName.minValidationErrorMessage
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextboxInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                error={!!errors.lastName}
                                type="text"
                                placeholder={translation.lastName.placeholder}
                            />
                        )}
                    />
                    {errors.lastName && <ErrorSpan role="alert">{errors.lastName.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer item xs={12} sm={6} md={3} lg={3} sx={{ lineHeight: '20px' }}>
                    <Typography variant="body1">{translation.email.label}</Typography>
                    <Controller
                        name="emailAddress"
                        control={control}
                        aria-invalid={errors?.emailAddress || emailExistsError ? 'true' : 'false'}
                        rules={{
                            required: {
                                value: true,
                                message: translation.email.requiredErrorMessage
                            },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: translation.email.validationErrorMessage
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextboxInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                error={!!errors?.emailAddress || !!emailExistsError}
                                placeholder={translation.email.placeholder}
                            />
                        )}
                    />
                    {(errors.emailAddress || emailExistsError) && (
                        <ErrorSpan role="alert">{errors?.emailAddress?.message || emailExistsError}</ErrorSpan>
                    )}
                </GridContainer>
                <GridContainer item xs={12} sm={6} md={3} lg={3} sx={{ lineHeight: '20px' }}>
                    <Typography variant="body1">{translation.phonenumber.label}</Typography>
                    <Controller
                        name="phoneNumber"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: translation.phonenumber.requiredErrorMessage
                            },
                            pattern: {
                                value: /^[2-9]{1}[0-9]{2}[0-9]{3}[0-9]{4}$/i,
                                message: translation.phonenumber.validationErrorMessage
                            }
                        }}
                        render={({ field: { value, onChange } }) => (
                            <MaskedInput mask={'9999999999'} value={value} onChange={onChange}>
                                <TextboxInput
                                    type="text"
                                    error={!!errors?.phoneNumber}
                                    placeholder={translation.phonenumber.placeholder}
                                />
                            </MaskedInput>
                        )}
                    />
                    {errors.phoneNumber && <ErrorSpan role="alert">{errors.phoneNumber.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer item xs={12} sm={6} md={3} lg={3} sx={{ lineHeight: '20px' }}>
                    <Typography variant="body1">{t('TEXT_ADMIN_TENENT_NAME')}</Typography>
                    <Controller
                        name="tenantGroup"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Tenant Name is required'
                            }
                        }}
                        render={({ field }) => (
                            <ComboSelect
                                {...field}
                                onSelectChange={field.onChange}
                                selectedValue={selectedOption || ''}
                                options={tenentGroupOptions}
                            />
                        )}
                    />
                    {errors.tenantGroup && <ErrorSpan role="alert">{errors.tenantGroup.message}</ErrorSpan>}
                </GridContainer>
            </GridContainer>
        </form>
    );
}
