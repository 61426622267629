import styled from '@emotion/styled';
import { Icon } from '../../../atoms';
import { NavLink } from '../Link';
import { NavDropdown } from '../Dropdown';
import { useTranslation } from 'react-i18next';

const Container = styled('span')<{ isActive: boolean }>(({ isActive = false }) => ({
    display: 'flex',
    alignItems: 'center',
    '& > #person': {
        marginRight: '5px',
        width: '16px',
        height: '16px'
    },
    '& > #chevron-down': {
        width: '15px',
        height: '10px',
        ...(isActive && {
            transform: 'rotate(180deg)'
        })
    }
}));

export const Name = styled('span')(() => ({
    marginRight: '8px',
    textTransform: 'uppercase'
}));

const DropdownButton = styled('button')(() => ({
    fontFamily: 'TT Commons Pro Condensed',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.02em',
    color: '#3769FF',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    textAlign: 'left',
    marginRight: 'auto',
    textTransform: 'uppercase',
    padding: 0,
    '&:not(:last-child)': {
        marginBottom: '8px'
    }
}));

const DropdownLegend = styled('span')(() => ({
    fontFamily: 'TT Commons Pro',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#354560',
    marginBottom: '18px'
}));

type Props = {
    loggedInUsername: string;
    userRole?: string;
    tenantId?: string;
    tenantsList?: any;
    tenant?:string;
    adminTitle?: string;
    isDropdownActive: boolean;
    onToggleDropdown(): void;
    onClickAdmin(): void;
    onClickLogout(): void;
};

export const NavUser = ({
    loggedInUsername,
    userRole,
    tenantsList,
    tenant,
    isDropdownActive,
    onToggleDropdown,
    onClickAdmin,
    onClickLogout
}: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <NavLink onClick={onToggleDropdown} style={{ marginRight: '37px' }} id="nav-user-button">
                <Container isActive={isDropdownActive}>
                    <Icon id="person" />
                    <Name>{loggedInUsername}</Name>
                    <Icon id="chevron-down" />
                </Container>
            </NavLink>
            <NavDropdown isVisible={isDropdownActive} parentId="nav-user-button" onClose={onToggleDropdown}>
                {tenant==='hantz' && tenantsList?.includes('hantz') ? null : userRole === 'admin' || userRole === 'ftadmin' ? (
                    <>
                        <DropdownButton type="button" onClick={onClickAdmin}>
                            {userRole}
                        </DropdownButton>
                        <DropdownLegend>Tenant Admin</DropdownLegend>
                    </>
                ) : null}
                <DropdownButton type="button" onClick={onClickLogout}>
                    {t('TEXT_MENU_LOGOUT')}
                </DropdownButton>
            </NavDropdown>
        </>
    );
};
