import { AddOutlined } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CLIENT_PROFILE_STAGE_KEYS } from '../../common/constants';
import { isStageDisabled } from '../../common/helper';
import { useAppSelector } from '../../common/hooks';
import { IconButton, Typography } from '../../components/atoms';
import { FundSourceChip } from '../../components/molecules';
import { UniversalCard } from '../../components/molecules/UniversalCard/UniversalCard';
import AddIncomeSourcesDrawer from '../../containers/add-income-sources-drawer/index';
import { AddInvestmentAccounts } from '../../containers/add-investment-accounts';
import FundingSourcesList from '../../containers/funding-source-list';
import { PATPalette } from '../../themes/palette';
import { ReactComponent as CashIcon } from '../../assets/icons/cash.svg';
import { removeNonNumeric } from '../../utils';
import { HeaderIcon } from './HeaderIcon';
import { InfoTooltip } from '../../components/molecules/InfoTooltip';

const StyledItemContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '12px',
    border: `1px solid ${PATPalette.neutral.grey[100]}`,
    background: '#FFF',
    boxShadow: '2px 2px 5px 1px rgba(212, 212, 212, 0.25)',
    margin: 0,
    padding: '20px 16px',
    height: '187px'
}));

const StyledIconButton = styled(IconButton)(({ theme, disabled }) => ({
    border: `1px solid ${disabled ? theme.palette.grey[300] : theme.palette.primary.main}`,
    width: theme.spacing(6.2),
    height: theme.spacing(6.2),
    color: theme.palette.primary.main
}));

const StyledAddOutlined = styled(AddOutlined)(({ theme }) => ({
    width: theme.spacing(3.6),
    height: theme.spacing(3.6)
}));

const StyledLinkedText = styled(Typography)(({ theme }) => ({
    fontWeight: '600',
    paddingBottom: '0',
    marginBottom: '0',
    marginTop: theme.spacing(2),
    lineHeight: theme.spacing(2.5),
    cursor: 'pointer'
}));

const StyledHeaderIconContainer = styled('div')<{ disabled: boolean }>(({ disabled }) => ({
    width: '32px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    background: disabled ? PATPalette.neutral.grey[100] : PATPalette.secondary.skyBlue[100],
    '& path': {
        fill: disabled ? PATPalette.neutral.grey[300] : PATPalette.secondary.skyBlue[400]
    }
}));

export const FundingSourcesCard = () => {
    const [isAddIncomeSourceDrawerOpen, setIsAddIncomeSourceDrawerOpen] = useState(false);
    const [isFundingSourceDrawerOpen, setIsFundingSourceDrawerOpen] = useState(false);
    const { t } = useTranslation();
    const [openAddFunds, setOpenAddFunds] = useState(false);
    const adviceClient = useAppSelector((state) => state.adviceClient);
    const { incomeSources, investmentsSources } = adviceClient.accounts;
    let annualContribution = 0;
    let amountIncomeSource = 0;
    let amountAccounts = 0;
    incomeSources.forEach((item) => {
        amountIncomeSource += item.frequency === 'Annually' ? item.amount : item.amount * 12;
    });
    investmentsSources.forEach((item) => {
        amountAccounts += +item.amount;
        annualContribution += +(item.frequency === 'Annually'
            ? Number(removeNonNumeric(item.recurringContributions))
            : Number(removeNonNumeric(item.recurringContributions)) * 12);
    });

    return (
        <UniversalCard
            header={t('TEXT_HEADING_ADD_FUNDING_SOURCE')}
            headerIcon={
                <HeaderIcon
                    isDisabled={isStageDisabled(adviceClient, CLIENT_PROFILE_STAGE_KEYS[3])}
                    icon={<CashIcon />}
                />
            }
            subcontent={
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {t('TEXT_SUBHEADING_ADD_FUNDING_SOURCE')}
                    <InfoTooltip placement="left-start" width={381} t="ADD_FUNDING_SOURCE_HELPER"></InfoTooltip>
                </span>
            }
            showSubcontent={true}
            showViewAll={incomeSources.length > 0 || investmentsSources.length > 0}
            onClickViewAll={(flag) => {
                setIsFundingSourceDrawerOpen(flag);
            }}
            onClick={() => {}}
            cardSx={
                isStageDisabled(adviceClient, CLIENT_PROFILE_STAGE_KEYS[3])
                    ? {
                          cursor: 'not-allowed',
                          '*': { 'pointer-events': 'none', filter: 'grayscale(100%)' }
                      }
                    : {}
            }
            actionMenuOptions={[]}
        >
            <Grid container columnGap={1.5} wrap="nowrap" sx={{ padding: '24px 0' }}>
                <Grid item xs={6}>
                    {incomeSources.length > 0 ? (
                        <FundSourceChip
                            title={t('TEXT_FUNDING_SOURCE_INCOME')}
                            subTitle={t('SUBTEXT_FUNDING_SOURCE_INCOME')}
                            headerIcon={
                                <StyledHeaderIconContainer
                                    disabled={isStageDisabled(adviceClient, CLIENT_PROFILE_STAGE_KEYS[3])}
                                >
                                    <CashIcon />
                                </StyledHeaderIconContainer>
                            }
                            amount={amountIncomeSource}
                            freqcontLabel={t('ANNUALLY')}
                            count={incomeSources.length}
                            action={true}
                        />
                    ) : (
                        <StyledItemContainer>
                            <StyledIconButton
                                disabled={isStageDisabled(adviceClient, CLIENT_PROFILE_STAGE_KEYS[3])}
                                iconName={
                                    <StyledAddOutlined
                                        sx={{
                                            color: isStageDisabled(adviceClient, CLIENT_PROFILE_STAGE_KEYS[3])
                                                ? PATPalette.neutral.grey[400]
                                                : PATPalette.primary.skyBlue
                                        }}
                                    />
                                }
                                bordered={true}
                                onClick={() => setIsAddIncomeSourceDrawerOpen(true)}
                            />
                            <StyledLinkedText
                                label={t('TEXT_FUNDING_SOURCE_INCOME')}
                                onClick={() => setIsAddIncomeSourceDrawerOpen(true)}
                                sx={{
                                    color: isStageDisabled(adviceClient, CLIENT_PROFILE_STAGE_KEYS[3])
                                        ? PATPalette.neutral.grey[400]
                                        : PATPalette.primary.skyBlue,
                                    borderBottom: `1px solid ${
                                        isStageDisabled(adviceClient, CLIENT_PROFILE_STAGE_KEYS[3])
                                            ? PATPalette.neutral.grey[400]
                                            : PATPalette.primary.skyBlue
                                    }`
                                }}
                            />
                        </StyledItemContainer>
                    )}
                </Grid>
                <Grid item xs={6}>
                    {investmentsSources.length > 0 ? (
                        <FundSourceChip
                            title={t('TEXT_FUNDING_SOURCE_ACCOUNT')}
                            subTitle={t('SUBTEXT_FUNDING_SOURCE_WEALTH')}
                            headerIcon={
                                <StyledHeaderIconContainer
                                    disabled={isStageDisabled(adviceClient, CLIENT_PROFILE_STAGE_KEYS[3])}
                                >
                                    <CashIcon />
                                </StyledHeaderIconContainer>
                            }
                            amount={amountAccounts}
                            annualContribution={annualContribution}
                            freqcontLabel={t('LABELS_ANNUAL_CONTRIBUTION') + ':'}
                            count={investmentsSources.length}
                            action={true}
                        />
                    ) : (
                        <>
                            <StyledItemContainer>
                                <StyledIconButton
                                    disabled={isStageDisabled(adviceClient, CLIENT_PROFILE_STAGE_KEYS[3])}
                                    iconName={
                                        <StyledAddOutlined
                                            sx={{
                                                color: isStageDisabled(adviceClient, CLIENT_PROFILE_STAGE_KEYS[3])
                                                    ? PATPalette.neutral.grey[400]
                                                    : PATPalette.primary.skyBlue
                                            }}
                                        />
                                    }
                                    bordered={true}
                                    onClick={() => setOpenAddFunds(true)}
                                />
                                <StyledLinkedText
                                    label={t('TEXT_FUNDING_SOURCE_ACCOUNT')}
                                    onClick={() => setOpenAddFunds(true)}
                                    sx={{
                                        color: isStageDisabled(adviceClient, CLIENT_PROFILE_STAGE_KEYS[3])
                                            ? PATPalette.neutral.grey[400]
                                            : PATPalette.primary.skyBlue,
                                        borderBottom: `1px solid ${
                                            isStageDisabled(adviceClient, CLIENT_PROFILE_STAGE_KEYS[3])
                                                ? PATPalette.neutral.grey[400]
                                                : PATPalette.primary.skyBlue
                                        }`
                                    }}
                                />
                            </StyledItemContainer>
                        </>
                    )}
                </Grid>
            </Grid>
            <AddIncomeSourcesDrawer
                setOpenDrawer={setIsAddIncomeSourceDrawerOpen}
                openDrawer={isAddIncomeSourceDrawerOpen}
            />
            <FundingSourcesList setOpenDrawer={setIsFundingSourceDrawerOpen} openDrawer={isFundingSourceDrawerOpen} />
            {openAddFunds && (
                <AddInvestmentAccounts
                    openDrawer={openAddFunds}
                    setOpenDrawer={() => setOpenAddFunds(false)}
                    mode="add"
                />
            )}
        </UniversalCard>
    );
};
