// import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
// import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { uipApiInstance } from '../index';
import { logEventToBackEnd } from '../../features/global/globalSlice';

export const updateUserDetails = createAsyncThunk(
    'admin/updateUser',
    // async (data: any, { dispatch, rejectWithValue }) => {
    async (data: any) => {
        try {
            const response = await uipApiInstance({
                method: 'POST',
                url: '/api/advisorportal/updateuser',
                withCredentials: false,
                data
            });
            return response.data.success;
        } catch (err) {
            console.log(err);
            // dispatch(logEventToBackEnd('ADMIN_UPDATE_USER_API_ERROR'));
            // return rejectWithValue(err);
        }
    }
);

export type FinancialUserType = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    role: string;
    tenant: string;
    tenants: string[];
};

export const addUserDetails = createAsyncThunk(
    'admin/addUser',
    async (financialAdvisor: FinancialUserType, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance({
                method: 'POST',
                url: '/api/advice/adduser',
                withCredentials: false,
                data: {
                    ...financialAdvisor,
                    settings: {
                        // hierarchy: {
                        //     manager_id: '00ucql4opCihuxT0X696',
                        //     regional_office_id: 'newyork'
                        // },
                        freemium: false
                    }
                }
            });

            return response.data?.success;
        } catch (e) {
            // console.log(e);
            dispatch(logEventToBackEnd('ADMIN_ADD_USER_API_ERROR'));
            return rejectWithValue(e);
        }
    }
);
