import { FormControl, FormControlLabel, FormLabel } from '@mui/material';
import { RadioGroup, Radio, RadioGroupProps } from '@mui/material';
import React, { useState } from 'react';

export interface RadioOptionProps extends RadioGroupProps {
    value?: React.ReactNode;
    options: { label: string; value: string; disabled?: boolean }[];
    label: string;
    // eslint-disable-next-line no-unused-vars
    onRadioChange?: (value: React.ReactNode) => void;
}

export const RadioOption = ({ options, value, onRadioChange, label, ...props }: RadioOptionProps) => {
    const [selectedValue, setSelectedValue] = useState(value);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = (event.target as HTMLInputElement).value;
        setSelectedValue(newValue);
        onRadioChange?.(newValue);
    };

    React.useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    return (
        <FormControl>
            <FormLabel sx={{ marginBottom: '1rem' }} id="demo-radio-buttons-group-label">{label}</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={selectedValue}
                name="radio-buttons-group"
                onChange={handleChange}
                {...props}
            >
                {options.map((option) => (
                    <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio disabled={!!option.disabled} />}
                        label={option.label}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};
