import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { ComboSelect, RadioOption, TextboxInput, Typography, BasicDatePicker } from '../../components/atoms';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../common/hooks';
// import Percent from '@mui/icons-material/Percent';
import dayjs, { Dayjs } from 'dayjs';
import { formatCurrencyDollar } from '../../utils';

const ErrorSpan = styled('span')(({}) => ({
    fontSize: '13px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '12px',
    color: '#DC0546',
    margin: 0
}));

const GridContainer = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
    '.MuiFormControl-root': {
        width: '100%'
    },
    gap: '0.5rem'
}));

const StyledForm = styled('form')(() => ({
    
}));

const StyledInfoText = styled(Typography)(() => ({
    fontSize: '16px',
    lineHeight: '12px',
    fontWeight: 400,
    fontStyle: 'italic',
    margin: '0'
}));

const StyledSourceOwnerRadioOption = styled(RadioOption)(() => ({
    '.MuiFormControlLabel-root': {
        '& .MuiTypography-root': {
            fontSize: '1rem',
            fontWeight: '500',
            lineHeight: '1.25rem',
            margin: 0,
            width: '120px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    gap: '8px'
}));

export const AddIncomeSourcesForm = ({
    onSubmit,
    handleSubmit,
    control,
    errors,
    sourceOwnerOptions,
    keyId,
    convertToCommaFormat,
    getValues
}: any) => {
    const sourceTypeDropdownValues = useAppSelector((state) => state.global.orgInfo?.incomeSourceType);
    const { t, i18n } = useTranslation();
    const currentSourceTypeDropdownValues = sourceTypeDropdownValues[i18n.language] || [];
    const beginningYearValue = useWatch({ control, name: 'beginningYear' });
    const currentDate = dayjs();

    const frequency = useWatch({ control, name: 'frequency' });

    const handleDateChange = (value: dayjs.Dayjs, onChange: (event: dayjs.Dayjs) => void) => {
        if (frequency === 'Annually') {
            onChange(value?.startOf('year'));
        } else {
            onChange(value?.startOf('month'));
        }
        return;
    };

    return (
        <StyledForm
            onSubmit={handleSubmit(onSubmit)}
            id="add-income-sources-form"
            data-testid="add-income-sources-form"
            key={keyId}
        >
            <Grid marginBottom={2}>
                <StyledInfoText variant="body1" color="neutral.grey.400" label={t('TEXT_REQUIRED_FIELDS')} />
            </Grid>
            <Grid container gap={2} fontWeight="500">
                <GridContainer item xs={12} key="sourceNameContainer">
                    <Controller
                        name="sourceName"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: { value: true, message: t('REQUIRED_SOURCE_NAME') },
                            pattern: {
                                value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s'(),.-]*$/,
                                message: t('SOURCE_NAME_VALIDATION_MESSAGE')
                            },
                            maxLength: {
                                value: 30,
                                message: t('NAME_MAX_LENGTH_VALIDATION')
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <>
                                <Typography id="source-name-input-label" variant="labels.large" >{`${t('SOURCE_NAME')} *`}</Typography>
                                <TextboxInput
                                    error={!!errors.sourceName}
                                    type="text"
                                    placeholder={t('SOURCE_NAME')}
                                    fullWidth
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    value={value}
                                    inputRef={ref}
                                />
                            </>
                        )}
                    />
                    {errors.sourceName && <ErrorSpan role="alert">{errors.sourceName.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer item xs={12} key="sourceTypeContainer">
                    <Controller
                        name="sourceType"
                        control={control}
                        rules={{
                            required: { value: true, message: t('REQUIRED_SOURCE_TYPE') }
                        }}
                        render={({ field: { onChange, value, name, onBlur } }) => (
                            <ComboSelect
                                onSelectChange={onChange}
                                onBlur={onBlur}
                                selectedValue={value}
                                name={`add-income-sources-${name}`}
                                label={`${t('SOURCE_TYPE')} *`}
                                placeholder={t('TEXT_SELECT')}
                                options={currentSourceTypeDropdownValues}
                                error={!!errors.sourceType}
                            />
                        )}
                    />
                    {errors.sourceType && <ErrorSpan role="alert">{errors.sourceType.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer item xs={12} key="sourceOwnerContainer">
                    <Controller
                        name="sourceOwner"
                        control={control}
                        rules={{
                            required: { value: true, message: 'Source owner is required' }
                        }}
                        render={({ field: { onChange, value, name, onBlur } }) => (
                            <StyledSourceOwnerRadioOption
                                onRadioChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={`add-income-sources-${name}`}
                                label={`${t('SOURCE_OWNER')} *`}
                                options={sourceOwnerOptions}
                            />
                        )}
                    />
                    {errors.sourceOwner && <ErrorSpan role="alert">{errors.sourceOwner.message}</ErrorSpan>}
                </GridContainer>
                <Grid sx={{ display: 'flex', width: '100%' }} item gap={1}>
                <GridContainer item key="amountContainer">
                    <Controller
                        name="amount"
                        control={control}
                        rules={{
                            required: { value: true, message: t('TEXT_THIS_IS_REQUIRED_FIELD') },
                            validate: {
                                weight: (value) => (value < 0 ? t('TEXT_AMOUNT_VALIDATION') : undefined)
                            }
                        }}
                        defaultValue={null}
                        render={({ field: { onChange, onBlur, value, ref, name } }) => (
                            <>
                                <Typography id="amount-input-label" variant="labels.large">{`${t('AMOUNT')} *`}</Typography>
                                <TextboxInput
                                    onBlur={(e: any) => {
                                        if (e.target.value) {
                                            convertToCommaFormat(e, name);
                                        }
                                        onBlur();
                                    }}
                                    onChange={(e) => {
                                        e.target.value = formatCurrencyDollar(e.target.value);
                                        onChange(e);
                                    }}
                                    value={value}
                                    name={`add-income-sources-${name}`}
                                    inputRef={ref}
                                    error={!!errors.amount}
                                    min={0}
                                    type="text"
                                    placeholder={t('AMOUNT')}
                                />
                            </>
                        )}
                    />
                    {errors.amount && <ErrorSpan role="alert">{errors.amount.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer sx={{ flexGrow: '1' }} item key="frequencyContainer">
                    <Controller
                        name="frequency"
                        control={control}
                        rules={{
                            required: { value: true, message: 'Frequency is required' }
                        }}
                        defaultValue={null}
                        render={({ field: { onChange, onBlur, value, name } }) => (
                            <ComboSelect
                                onSelectChange={onChange}
                                onBlur={onBlur}
                                selectedValue={value}
                                name={`add-income-sources-${name}`}
                                label={`${t('FREQUENCY')} *`}
                                placeholder="Select"
                                options={[
                                    { label: t('ANNUALLY'), value: 'Annually' },
                                    { label: t('MONTHLY'), value: 'Monthly' }
                                ]}
                                error={errors.frequency}
                            />
                        )}
                    />
                    {errors.frequency && <ErrorSpan role="alert">{errors.frequency.message}</ErrorSpan>}
                </GridContainer>
                </Grid>
                <GridContainer item xs={12} key="beginningYearContainer">
                    <Controller
                        name="beginningYear"
                        control={control}
                        rules={{
                            validate: (value) => {
                                if (value) {
                                    if(!value.isValid()) return t('TEXT_VALID_DATE_ERROR');
                                    if (getValues('frequency') === 'Annually') {
                                        const selectedYear = value.year();
                                        const currentYear = dayjs().year();

                                        if (selectedYear <= currentYear) {
                                            return t('TEXT_VALID_DATE_ERROR');
                                        }
                                    } else {
                                        if (dayjs().isAfter(value) || dayjs().isSame(value)) {
                                            return t('TEXT_VALID_DATE_ERROR');
                                        }
                                    }
                                }
                                return (value !== null && value !== undefined) || t('TEXT_THIS_IS_REQUIRED_FIELD');
                            }
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <>
                                <Typography id="beginning-year-input-label" variant="labels.large">{`${t('BEGINING_YEAR')} *`}</Typography>
                                <BasicDatePicker
                                    enabled
                                    type="single"
                                    {...(frequency === 'Annually' ? { isYyyy: true } : { isMmYyyy: true })}
                                    startDate={currentDate.add(1, 'month').startOf('month').toString()}
                                    value={value ? dayjs(value) : null}
                                    onChange={(event: Dayjs) => handleDateChange(event, onChange)}
                                    onBlur={onBlur}
                                    data-testid="beginningYear"
                                    customOpenIcon
                                    hasError={!!errors.beginningYear}
                                />
                            </>
                        )}
                    />
                    {errors.beginningYear && <ErrorSpan role="alert">{errors.beginningYear.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer item xs={12} key="endYearContainer">
                    <Controller
                        name="endYear"
                        control={control}
                        rules={{
                            validate: (value) => {
                                if (value) {
                                    if(!value.isValid()) return t('TEXT_VALID_END_DATE_ERROR');    
                                    const startDate = getValues('beginningYear');
                                    if (getValues('frequency') === 'Annually') {
                                        const selectedYear = value.year();
                                        const startYear = startDate?.year();

                                        if (startYear && selectedYear <= startYear) {
                                            return t('TEXT_VALID_END_DATE_ERROR');
                                        }
                                    } else {
                                        if (startDate?.isAfter(value) || startDate?.isSame(value)) {
                                            return t('TEXT_VALID_END_DATE_ERROR');
                                        }
                                    }
                                }
                                return (value !== null && value !== undefined) || t('TEXT_THIS_IS_REQUIRED_FIELD');
                            }
                        }}
                        defaultValue={null}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <>
                                <Typography id="end-year-input-label" variant="labels.large">{`${t('END_YEAR')} *`}</Typography>
                                <BasicDatePicker
                                    enabled
                                    type="single"
                                    {...(frequency === 'Annually' ? { isYyyy: true } : { isMmYyyy: true })}
                                    startDate={
                                        beginningYearValue
                                            ? beginningYearValue.add(1, 'month').startOf('month').toString()
                                            : currentDate.add(1, 'month').startOf('month').toString()
                                    }
                                    value={value ? dayjs(value) : null}
                                    onChange={(event: Dayjs) => handleDateChange(event, onChange)}
                                    onBlur={onBlur}
                                    data-testid="endYear"
                                    customOpenIcon
                                    hasError={!!errors.endYear}
                                />
                            </>
                        )}
                    />
                    {errors.endYear && <ErrorSpan role="alert">{errors.endYear.message}</ErrorSpan>}
                </GridContainer>
                {/* Commenting as per AEX-1253 */}
                {/* <GridContainer item xs={12} key="escalateIncomeContainer">
                    <Controller
                        name="escalateIncome"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('ESCALATION_INCOME_REQUIRED')
                            },
                            max: {
                                value: 10,
                                message: t('TEXT_MAX_VALUE')
                            },
                            min: {
                                value: 0,
                                message: t('TEXT_MIN_VALUE')
                            }
                        }}
                        defaultValue={undefined}
                        render={({ field: { value, name, onBlur, onChange, ref } }) => (
                            <>
                                <InputLabel id="escalation-icome-input-label">{`${t(
                                    'ESCALATE_INCCOME'
                                )} *`}</InputLabel>
                                <TextboxInput
                                    onBlur={onBlur}
                                    onChange={(e) => onChange(parseInt(e.target.value))}
                                    value={value}
                                    name={`add-income-sources-${name}`}
                                    inputRef={ref}
                                    error={!!errors.escalateIncome}
                                    min={0}
                                    max={10}
                                    type="number"
                                    placeholder={t('ESCALATE_INCCOME')}
                                    icon={<Percent />}
                                    iconPlacement="end"
                                />
                            </>
                        )}
                    />
                    {errors.escalateIncome && <ErrorSpan role="alert">{errors.escalateIncome.message}</ErrorSpan>}
                </GridContainer> */}
            </Grid>
        </StyledForm>
    );
};
