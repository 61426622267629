export type RiskProfileType = 'Conservative' | 'Moderate' | 'Aggressive';

export const getRiskProfileMessage = (risk: RiskProfileType) => {
    const riskProfileMessages = new Map<RiskProfileType, string>([
        ['Conservative', 'CLIENT_PROFILE_RISK_TOLERANCE_HELPER_CONSERVATIVE'],
        ['Moderate', 'CLIENT_PROFILE_RISK_TOLERANCE_HELPER_MODERATE'],
        ['Aggressive', 'CLIENT_PROFILE_RISK_TOLERANCE_HELPER_AGGRESSIVE']
    ]);

    return riskProfileMessages.get(risk) || '';
};
