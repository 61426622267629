import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { logEventToBackEnd } from '../global/globalSlice';
import { uipApiInstance } from '../../services';
import {
    AdvisorConsoleStore,
    AdvisorConsoleStoreFilters,
    GetAdvisorConsoleProposalsPayload,
    GetProposalsResponse
} from './types';
import _sumBy from 'lodash/sumBy';
import { GoalTypeEnum, GoalTypeTitlemapping } from '../../common/types/clients-entity';
import { AdminTableItem } from 'components/molecules/AdminTable';
import {
    calculateEquityFixedIncome,
    filterDataItems,
    getItemsByPage,
    getNumberOfPages
} from './utils';
import _uniqBy from 'lodash/uniqBy';
import _sortBy from 'lodash/sortBy';
import {
    priorityOptions,
    riskProfileOptions,
    statusOptions,
    typeOptions
} from '../../common/constants';
import dayjs from 'dayjs';

export const getAdvisorConsoleProposals = createAsyncThunk(
    'advisorConsole/getproposals',
    async (
        { tenant }: GetAdvisorConsoleProposalsPayload,
        { dispatch, rejectWithValue, getState }
    ) => {
        try {
            const { data: apiResponse } = await uipApiInstance<GetProposalsResponse>({
                method: 'GET',
                url: `/api/advice/getproposals?tenant=${tenant}`
            });

            const fullState: any = getState();

            const dataItems: AdminTableItem[] =
                apiResponse?.data?.map(
                    ({
                        clientFirstName,
                        clientLastName,
                        form_fields = [],
                        global,
                        accounts,
                        _id,
                        riskProfile,
                        __meta,
                        response
                    }: any) => {
                        const subItems =
                            form_fields?.map((formField: any) => {
                                const { data, id, goalId } = formField;

                                const goal = (
                                    GoalTypeTitlemapping as { [key: string]: GoalTypeEnum }
                                )[data.wealthGoalType!];

                                const cleanPortfolioValue = Number(
                                    String(data?.goalAmount || 0).replace(/[^\w\s]/gi, '')
                                );

                                return {
                                    goal,
                                    portfolioValue: cleanPortfolioValue, // TODO: verify
                                    probabilityofSuccess: data.probabilityOfSuccess,

                                    id: [_id, id, goalId].filter(Boolean).join('_'),
                                    goalPriority: data?.goalPriority || '',
                                    goalType: data?.goalType,
                                    equityFixedIncome:
                                        data?.equityFixedIncome ||
                                        calculateEquityFixedIncome({
                                            portfolios: fullState.global.portfolio,
                                            recommendedPortfolioId:
                                                response?.[0]?.response?.body?.analysisReport
                                                    ?.recommendedPortfolioId
                                        })
                                };
                            }) || [];

                        const numberofGoals = ~~form_fields?.length;

                        const portfolioValue = numberofGoals
                            ? _sumBy(accounts?.incomeSources, 'amount') +
                              _sumBy(accounts?.investmentsSources, 'amount')
                            : 0;

                        const planCreationDate = dayjs(__meta?.timestamp).isValid()
                            ? dayjs(__meta?.timestamp).format('MM/DD/YYYY')
                            : '-';

                        // const portfolioApproach = numberofGoals ? 'Multiple' : '';

                        return {
                            id: _id,
                            clientGoalName: [clientFirstName, clientLastName]
                                .filter(Boolean)
                                .join(' '),
                            status: global?.status ?? 'draft',
                            probabilityOfSuccess: global?.probabilityOfSuccess,
                            equityFixedIncome: global?.equityFixedIncome,
                            // portfolioApproach,
                            riskGoalPriority: riskProfile,
                            portfolioValue,
                            numberofGoals,
                            subItems,
                            planCreationDate
                        };
                    }
                ) || [];

            const itemsPerPage = 10;

            const pagination: AdvisorConsoleStore['pagination'] = {
                page: 1,
                totalPages: getNumberOfPages(dataItems.length, itemsPerPage),
                itemsPerPage,
                totalItems: dataItems.length
            };

            const items = getItemsByPage({
                dataItems,
                itemsPerPage,
                page: 1
            });

            const clients = _sortBy(
                _uniqBy(
                    dataItems
                        ?.filter(({ clientGoalName }) => !!clientGoalName)
                        ?.map(({ clientGoalName }) => {
                            const name = clientGoalName?.toUpperCase();

                            return {
                                value: name,
                                label: clientGoalName
                            };
                        }),
                    'value'
                ),
                'label'
            );

            const keyValueRequest = apiResponse?.data?.reduce(
                (acc: Record<string, any>, { _id, ...rest }: any) => {
                    acc[_id] = { ...rest, _id };
                    return acc;
                },
                {}
            );

            return {
                dataItems,
                items,
                pagination,
                clients,
                keyValueRequest
            };
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('GET_PROPOSALS_API_ERROR'));
            return rejectWithValue(err);
        }
    }
);

const INITIAL_STATE: AdvisorConsoleStore = {
    loading: false,
    pagination: {
        page: 1,
        totalPages: 1,
        itemsPerPage: 10,
        totalItems: 0
    },
    items: [],
    dataItems: [],
    clients: [],
    error: false,
    filters: {
        status: statusOptions[0]?.value,
        riskProfile: riskProfileOptions[0]?.value,
        type: typeOptions[0]?.value,
        priority: priorityOptions[0]?.value
    },
    hasFilters: false,
    keyValueRequest: {}
};

const advisorConsole = createSlice({
    name: 'advisorConsole',
    initialState: INITIAL_STATE,
    reducers: {
        changePage: (state, action: PayloadAction<number>) => {
            const {
                pagination: { itemsPerPage }
            } = state;

            const dataItems = filterDataItems({
                dataItems: state.dataItems,
                filters: state.filters
            });

            const page = action.payload;

            state.pagination.page = page;

            state.items = getItemsByPage({
                dataItems,
                itemsPerPage,
                page
            });
        },
        changeItemsPerPage: (state, action: PayloadAction<number>) => {
            const page = 1; // force back to page 1 to prevent bugs
            const itemsPerPage = action.payload;

            const dataItems = filterDataItems({
                dataItems: state.dataItems,
                filters: state.filters
            });

            state.pagination = {
                ...state.pagination,
                page,
                totalPages: getNumberOfPages(dataItems.length, itemsPerPage),
                itemsPerPage
            };

            const items = getItemsByPage({
                dataItems,
                itemsPerPage,
                page
            });

            state.items = items;
        },
        setFilters: (state, action: PayloadAction<AdvisorConsoleStoreFilters>) => {
            const page = 1; // force back to page 1 to prevent bugs

            /**
             *
             *
             *
             *
             */
            /// TODO: FINISH FILTERS VALIDAITONS

            const filteredDataItems = filterDataItems({
                dataItems: state.dataItems,
                filters: action.payload
            });

            const items = getItemsByPage({
                dataItems: filteredDataItems,
                itemsPerPage: state.pagination.itemsPerPage,
                page
            });

            state.items = items;

            state.pagination = {
                ...state.pagination,
                page,
                totalPages: getNumberOfPages(
                    filteredDataItems.length,
                    state.pagination.itemsPerPage
                ),
                totalItems: filteredDataItems.length
            };

            state.filters = action.payload;

            state.hasFilters = true;
        },
        resetAdviceConsoleReducer: () => INITIAL_STATE
    },
    extraReducers: (builder) => {
        builder.addCase(getAdvisorConsoleProposals.pending, (state) => {
            state.loading = true;
        }),
            builder.addCase(getAdvisorConsoleProposals.fulfilled, (state, action) => {
                const { dataItems, items, pagination, clients, keyValueRequest } = action.payload;

                state.dataItems = dataItems;
                state.items = items;
                state.pagination = pagination;
                state.clients = clients;
                state.keyValueRequest = keyValueRequest;
                state.loading = false;
                state.error = false;
            }),
            builder.addCase(getAdvisorConsoleProposals.rejected, (state) => {
                state.loading = false;
                state.error = true;
            });
    }
});

export const { actions: advisorConsoleActions } = advisorConsole;

export default advisorConsole.reducer;
