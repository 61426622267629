import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../common/hooks';
import { PATPalette } from '../../../themes/palette';
import { Typography } from '../../atoms';

export const Footer = ({
    // eulaVariant = false,
    showFooterWithoutOptions = false,
    primaryText = '',
    secondaryText,
    links = []
}: {
    eulaVariant?: boolean;
    showFooterWithoutOptions?: boolean;
    primaryText: string | React.ReactNode;
    secondaryText?: string | React.ReactNode;
    links?: { label: string; link: string }[];
}) => {
    const { t } = useTranslation();
    const isAuthLoading = useAppSelector((state) => state.auth.loading);
    // const isTrialSite = useAppSelector(
    //     (state) => state.global.globalConfig?.disclaimers?.showTrialFooter
    // );

    const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

    if (!(isAuthenticated && !isAuthLoading) && !showFooterWithoutOptions) {
        return null;
    }

    return (
        <Container>
            {!showFooterWithoutOptions && (
                <FooterWhiteContainer>
                    {/* TODO: is that really necessary? */}
                    {/* {isTrialSite && (
                    <Typography
                        variant="body.caption"
                        color="neutral.grey.400"
                        data-testid="footer-description-special"
                    >
                        {t('DESCRIPTION_FOOTER_TRIAL_SITE')}
                    </Typography>
                )} */}

                    <Typography
                        variant="body.caption"
                        color="neutral.grey.400"
                        data-testid="footer-description-special"
                    >
                        {t('DESCRIPTION_FOOTER_ONE')}
                    </Typography>
                </FooterWhiteContainer>
            )}

            <FooterBlueContainer data-testid="footer-description-two">
                <Typography variant="body.caption" color="neutral.white">
                    {primaryText}
                </Typography>

                {secondaryText && (
                    <Typography variant="body.caption" color="neutral.white">
                        {secondaryText}
                    </Typography>
                )}

                <div style={{ display: 'flex', gap: '24px' }}>
                    {links.map((el, index) => (
                        <a
                            href={el.link}
                            target="_blank"
                            rel="noreferrer"
                            key={`footer-link-${index}`}
                            style={{
                                textUnderlineOffset: '3px',
                                color: PATPalette.neutral.white
                            }}
                        >
                            <Typography variant="body.caption" color="neutral.white">
                                {el.label}
                            </Typography>
                        </a>
                    ))}
                </div>
            </FooterBlueContainer>
        </Container>
    );
};

const FooterWhiteContainer = styled('div')(() => ({
    backgroundColor: 'layout.primary',
    padding: '40px 40px 16px',
    background: PATPalette.neutral.white,
    display: 'flex',
    flexDirection: 'column'
}));

const FooterBlueContainer = styled('div')(() => ({
    backgroundColor: 'layout.primary',
    padding: '8px 40px',
    justifyContent: 'space-between',
    background: PATPalette.primary.skyBlue,
    display: 'flex',
    alignItems: 'center'
}));

const Container = styled('footer')(() => ({
    position: 'relative',
    bottom: 0,
    width: '100%'
}));
